export default function LoadingSpinner() {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                flexGrow: 0,
            }}
        >
            <div
                role="status"
                aria-label="Loading"
                style={{
                    width: "1em",
                    height: "1em",
                    border: "2px solid #ccc",
                    borderTop: "2px solid #333",
                    borderRadius: "50%",
                    animation: "spin 1s linear infinite",
                }}
            />
            <style>
                {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
            </style>
        </div>
    )
}
